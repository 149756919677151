<template>
  <v-container class="payment-type payment-type-redirect">
    <div
      class="payment-type-title"
      v-html="paymentType.title"
      v-if="paymentType.title"
    ></div>
    <div class="payment-type-description" v-html="paymentType.descr"></div>
    <PrebilledAmount
      :mode="mode"
      :paymentTypeId="paymentType.paymentTypeId"
      :order-id="orderId"
    />
    <PaymentAuthList
      @selectPaymentAuth="selectPaymentAuth"
      @addNewCard="addNewCard"
      @toggleRememberCard="toggleRememberCard"
      :paymentTypeId="paymentType.paymentTypeId"
    />
    <v-checkbox
      v-model="rememberNewCard"
      :label="$t('paymentTypeList.rememberNewCard')"
    />
    <ResponseMessage :response="response" />

    <v-row class="d-flex justify-end">
      <v-col
        cols="12"
        md="6"
        class="d-flex align-center"
        v-if="mode != 'order' && !options.hidePrevButton"
      >
        <LinkButton
          v-if="
            !$vuetify.breakpoint.xs &&
              options.prevButton &&
              options.prevButton.type === 'link-button'
          "
          :class="options.prevButton.class"
          :options="options.prevButton.options"
          :label="options.prevButton.label"
          @click="goBack"
        ></LinkButton>
        <v-btn
          v-else
          :color="options.back.color"
          :outlined="options.back.outline"
          depressed
          v-on:click="goBack"
          :disabled="loading"
          large
        >
          {{ options.back.label }}
        </v-btn>
      </v-col>
      <v-col cols="12" md="6" class="d-flex justify-end">
        <v-btn
          v-if="options.confirm.show"
          :color="options.confirm.color"
          :outlined="options.confirm.outline"
          class="mt-3 mt-sm-0 ml-2 gateway-btn"
          v-on:click="buy"
          depressed
          large
        >
          {{ options.confirm.label }}
        </v-btn>
      </v-col>
    </v-row>
    <i18n
      v-if="$te(`paymentTypeList.${paymentType.gatewayClient}.caption`)"
      :path="`paymentTypeList.${paymentType.gatewayClient}.caption`"
      tag="div"
      class="caption"
    />
  </v-container>
</template>
<script>
import PrebilledAmount from "./PrebilledAmount";
import PaymentAuthList from "./PaymentAuthList";
import ResponseMessage from "@/components/ResponseMessage";
import { payment, handlePayResponseKo } from "./gatewayUtilsService";
import OrderService from "~/service/orderService";
import gateway from "~/mixins/gateway";
import LinkButton from "~/components/LinkButton.vue";

import { mapActions } from "vuex";

export default {
  name: "PaymentTypeMonetaweb",
  mixins: [gateway],
  data() {
    return {
      loading: false,
      error: null,
      selectedPaymentAuthId: null,
      rememberNewCard: false,
      response: {}
    };
  },
  components: { PrebilledAmount, PaymentAuthList, ResponseMessage, LinkButton },
  methods: {
    ...mapActions({
      buyCart: "cart/buyCart"
    }),
    async buy() {
      let vm = this;
      vm.loading = true;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        if (vm.mode == "cart") {
          let cart = await vm.buyCart({
            paymentTypeId: this.paymentType.paymentTypeId,
            userPaymenthAuthId: this.selectedPaymentAuthId,
            paymentData: paymentData
          });
          if (cart) {
            vm.payResponseOk(cart);
          } else {
            this.$router.push("/payment-error");
          }
        } else if (vm.mode == "order") {
          let response = await OrderService.modifyOrderPaymentType(
            vm.orderId,
            this.paymentType.paymentTypeId,
            this.selectedPaymentAuthId,
            paymentData
          );
          if (response) {
            vm.payResponseOk(response);
          } else {
            this.$router.push("/cart/payment-error");
          }
        } else if (vm.mode == "duedate") {
          //           PaymentDueService.duedatePay(vm.paymentDueDateIds, vm.paymentTypeId, paymentAuthId, paymentData).then(
          //   payResponseOk,
          //   payResponseKo
          // );
        }
      } catch (err) {
        vm.response = err;
      } finally {
        vm.loading = false;
      }
    },
    async addNewCard() {
      let vm = this;
      try {
        let paymentData = { remember_card: this.rememberNewCard };
        let cart = await vm.buyCart({
          paymentTypeId: this.paymentType.paymentTypeId,
          paymentData: paymentData
        });
        if (cart) {
          vm.payResponseOk(cart);
        } else {
          this.$router.push("/payment-error");
        }
      } catch (err) {
        vm.error = err.message;
      } finally {
        vm.loading = false;
      }
    },
    payResponseOk(cart) {
      let vm = this;
      let orderId = null;
      global.EventBus.$emit("pay-response-ok", cart);
      if (cart && cart.paymentUrl) {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        vm.doRedirect(cart.paymentUrl, cart.paymentParameters, "get", orderId);
        this.loading = false;
      } else {
        orderId = cart.order ? cart.order.orderId : cart.orderId;
        this.$router.push("/cart/payment-completed/" + orderId);
        this.loading = false;
      }
    },
    payResponseKo(data) {
      this.loading = false;
      handlePayResponseKo(data, this.orderId);
    },
    doRedirect(paymentUrl, paymentParameters, method, orderId) {
      payment(paymentUrl, paymentParameters, method, orderId);
    },
    goBack() {
      this.$router.push("/checkout");
    },
    selectPaymentAuth(paymentAuthId) {
      this.selectedPaymentAuthId = paymentAuthId;
    },
    toggleRememberCard(value) {
      this.rememberNewCard = value;
    }
  }
};
</script>
